/*--------------------------------------------------------------
MAP > FRANCE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Unbind
- Go back to world
- Close department
- Change Tooltip
- Position Tooltip
- Open Dept
- Calc measures of region
- Zoom on Dept
- Append producers
- Create producer link
- Place producer point
- CSS
--------------------------------------------------------------*/

class FranceMap extends MapProducteurs {
    /*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
    constructor({ id, world_map_id, tooltip_id }) {
        // Init
        let query = `{
			areas: regions (not_empty: true) {
				name
				svg_id
			}
		}`;
        super({ id, query, tooltip_id });
        this.world_map = $$.id(world_map_id);
        this.prod_points = [];

        this.bind();
    }

    /*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
    bind() {
        // Close France and go back 2 world
        // this.map.onclick = e => this.back_to_world(e);

        // Make Tooltip follow the mouse (even when hidden)
        this.map.onmousemove = (e) => this.position_tooltip(e);

        this.paths.foreach((path) => {
            // Open tooltip on hover
            path.onmousemove = (e) => this.change_tooltip(e, path);

            // Open Dept on click
            path.onclick = (_) => this.open_region(path);
        });

        // Hide tooltip when mouse out of france
        this.map_group.onmouseleave = (e) => this.hide_tooltip();

        // Position producers on window resize
        const replace_producers = (_) => {
            this.prod_points.foreach(({ link, producer }) => {
                this.calc_region();
                this.place_producer(link, producer);
            });
        };
        window.on("resize", replace_producers);
    }

    /*--------------------------------------------------------------
	-	Unbind
	--------------------------------------------------------------*/
    unbind() {
        this.map.onclick = null;
        this.map.onmousemove = null;
        this.paths.foreach((path) => {
            path.onmousemove = null;
            path.onclick = null;
        });
    }

    /*--------------------------------------------------------------
	-	Go back to world
	--------------------------------------------------------------*/
    back_to_world(e) {
        // If click outside France AND we're not paning
        if (
            e.target.nodeName !== "path" &&
            e.target.nodeName !== "g" &&
            !this.moving
        ) {
            this.hide();
            page.world_map.show();
        }
    }

    /*--------------------------------------------------------------
	-	Close department
	--------------------------------------------------------------*/
    close_region(e) {
        // If this is not the active region AND we're not paning
        if (e.target !== this.active_region && !this.moving) {
            // Remove producers' points
            this.prod_points.foreach(({ link }) =>
                link.classList.add("hidden")
            );

            // Scale back the region
            this.reset_transform(this.active_region);

            // Show back the France
            new Timeout(500, (_) => {
                this.show_zoom();
                this.prod_points.foreach(({ link }) => link.delete());
                this.prod_points = [];
                this.active_region.setAttribute("class", "");
                this.map.classList.remove("zoomed");
            });

            // Now we set back the events
            this.bind();
        }
    }

    /*--------------------------------------------------------------
	-	Change Tooltip
	--------------------------------------------------------------*/
    change_tooltip(e, path) {
        // Hide tooltip if path is disabled
        if (path.getAttribute("class") === "off") {
            this.hide_tooltip();
        }

        // Else we show the tooltip and changes its name
        else {
            this.show_tooltip();
            this.tooltip.textContent = this.areas[path.id].name;
        }
    }

    /*--------------------------------------------------------------
	-	Position Tooltip
	--------------------------------------------------------------*/
    position_tooltip(e) {
        // Position the tooltip just above the mouse
        let { x: offset_x, y: offset_y } = this.get_tooltip_offset({ tail: 6 });
        let [x, y] = [
            e.clientX - offset_x + 1, // 1px to right to center it above index of hand
            e.clientY - offset_y - 5, // 5px above the hand
        ];
        this.transform(this.tooltip, { translate: { x, y } });
    }

    /*--------------------------------------------------------------
	-	Open Dept
	--------------------------------------------------------------*/
    open_region(region) {
        // Check if the region is enabled
        if (region.getAttribute("class") === "off") return;

        // You get it
        this.hide_tooltip();
        this.hide_zoom();
        this.active_region = region;
        this.unbind();

        // Fetch all producers of the region
        let query = `
		query ($region: String) {
			frenchProducers (region: $region) {
				name: producer_name
				brand
				address
				url {
					fr
					en
				}
				position {
					x
					y
				}
			}
		}`;
        let variables = { region: region.id };
        ajax.graphql({ query, variables }).then(
            ({ data: { frenchProducers } }) => {
                this.producers = frenchProducers;
                this.calc_region();
                this.zoom_on_region();
                this.append_producers();
            }
        );

        // On click outside region, go back to France
        this.map.onclick = (e) => this.close_region(e);

        // We hide France except the region
        this.map.classList.add("zoomed");
        region.setAttribute("class", "active");
    }

    /*--------------------------------------------------------------
	-	Calc measures of region
	--------------------------------------------------------------*/
    calc_region() {
        // We get all the needed measures
        this.bbox = this.active_region.getBBox(); // -> region relative to svg viewBox
        this.rect = this.active_region.getBoundingClientRect(); // -> region relative to window

        // region can't be taller than 1/2 window height nor wider than 1/2 window width
        let max = {
            w: window.innerWidth / 2,
            h: window.innerHeight / 2,
        };
        let screen_ratio = max.w / max.h;
        let region_ratio = this.rect.width / this.rect.height;

        // if region wider than screen (ratio) -> scale region on width, else scale on height
        this.scale_factor =
            region_ratio > screen_ratio
                ? max.w / this.rect.width
                : max.h / this.rect.height;
    }

    /*--------------------------------------------------------------
	-	Zoom on Dept
	--------------------------------------------------------------*/
    zoom_on_region() {
        // center of region relative to france viewbox
        let region_center = {
            x: this.bbox.x + this.bbox.width / 2,
            y: this.bbox.y + this.bbox.height / 2,
        };

        // We set transform-origin at center of region
        this.transform_origin(this.active_region, region_center);

        // we calculate diff between viewbox center and region center
        let viewbox = this.map.viewBox.baseVal; // -> svg viewbox
        let translate = {
            x: viewbox.width / 2 + viewbox.x - region_center.x,
            y: viewbox.height / 2 + viewbox.y - region_center.y,
        };

        // We center the path and scale it
        this.transform(this.active_region, {
            scale: this.scale_factor,
            translate,
            svg: true,
        });
    }

    /*--------------------------------------------------------------
	-	Append producers
	--------------------------------------------------------------*/
    append_producers() {
        let map = $$.id("map");

        // for each producer, we create a link (colored point)
        this.producers.foreach((producer) => {
            // Create point
            let link = this.create_producer(producer);

            // Position point
            this.place_producer(link, producer);

            // Append point
            map.appendChild(link);
            this.prod_points.push({ link, producer });
        });
    }

    /*--------------------------------------------------------------
	-	Create producer link
	--------------------------------------------------------------*/
    create_producer(producer) {
        let name = document.createElement("h1");
        name.textContent = producer.brand;

        let address = document.createElement("p");
        address.textContent = producer.address;

        let tooltip = document.createElement("div");
        tooltip.appendChild(name);
        tooltip.appendChild(address);

        let a = document.createElement("a");
        a.className = "prod";
        a.href = producer.url[document.documentElement.lang];
        a.appendChild(tooltip);

        return a;
    }

    /*--------------------------------------------------------------
	-	Place producer point
	--------------------------------------------------------------*/
    place_producer(link, producer) {
        // b -> relative pos of producer from scaled region
        let b = {
            x:
                ((producer.position.x * this.rect.width) / this.bbox.width) *
                this.scale_factor,
            y:
                ((producer.position.y * this.rect.height) / this.bbox.height) *
                this.scale_factor,
        };

        // c -> scaled region dimensions
        let c = {
            x: this.rect.width * this.scale_factor,
            y: this.rect.height * this.scale_factor,
        };

        // d -> offset between screen and scaled region
        let d = {
            x: (window.innerWidth - c.x) / 2,
            y: (window.innerHeight - c.y) / 2,
        };

        // absolute pos of link
        link.style.left = d.x + b.x + "px";
        link.style.top = d.y + b.y + "px";
    }

    /*--------------------------------------------------------------
	-	CSS
	--------------------------------------------------------------*/
    transform_origin(el, { x, y }) {
        el.style.transformOrigin = `${x}px ${y}px`;
    }
    transform(el, { scale = 1, translate: { x, y }, svg }) {
        // If this is a svg element
        if (svg) {
            el.style.transform = `translate(${x}px, ${y}px) scale(${scale})`;
        } else {
            el.style.transform = `translate(${x}px, ${y}px) scale(${scale})`;
        }
    }
    reset_transform(el) {
        el.style.transform = "none";
    }
}
